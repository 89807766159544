@mixin customScrollBar {
  &::-webkit-scrollbar {
    width: 6px; 
    height: 7px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--pink);
    border: 2px solid var(--panelGray);
    border-radius: 50px;
  }
  
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0,0,0,0.2);
  }

  @-moz-document url-prefix() {
    .scroller {
      scrollbar-width: thin;
      scrollbar-color: #1e202a;
    }
  }
}