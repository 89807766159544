@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import './mixins/global.module.scss';

:root{
  --orange: #d95b2a;
  --purple: #ba51d9;
  --green: #38AA49;
  --blue: #4EA3C9;
  --pink: #E63970;
  --yellow: #EDA128;
  --dark: #011122;
  --panelGray: #232B41;
  --light: #f8f8f8;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 15px;
  font-size: clamp(0.95rem, 2vw, 1.1rem);
  line-height: 1.4;
}

body{
  &::-webkit-scrollbar {
    width: 6px; 
    height: 7px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--orange);
    border: 1px solid #232B41;
    border-radius: 50px;
  }
  
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0,0,0,0.2);;
  }

  @-moz-document url-prefix() {
    .scroller {
      scrollbar-width: thin;
      scrollbar-color: #1e202a;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

textarea{
  white-space: pre-wrap;
  word-wrap: break-word;
  height: 100%;
  overflow-y: scroll;
  border: 1px solid #ddd;
  margin-bottom: 0;
  border-radius: 3px;
}

//MUI Overrides

.MuiTablePagination-displayedRows{
  user-select: none;
}

.MuiDialogContent-root{
  max-height: 50vh;
  @include customScrollBar;
}
